/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Divider, Drawer, List, ListItem, ListItemButton, Menu, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useLazyGetUserQuery, usePostLogoutMutation } from '../../../service/auth';
import { useAppDispatch, useAppSelector } from '../../../hooks/store';
import { getFromStorage, removeFromStorage, setToStorage } from '../../../constants/storage';
import { STORAGE_KEYS } from '../../../constants/storageKeys';
import { getToken, resetAuth, role, setCredentials } from '../../../reducers/authSlice';
import { showError, showToast } from '../../../constants/toast';
import useAuth from '../../../hooks/useAuth';
import LogoutModal from '../../../Modals/logout';
import { useLazyGetNotificationsQuery } from '../../../service/tutorApi';

const TutorHeader = () => {

    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };
    const [getNotifications] = useLazyGetNotificationsQuery();
    const [notifications, setNotifcations] = useState<any>([]);
    const userDetails = useAuth();
    const [getProfile] = useLazyGetUserQuery();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open1 = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [openLog, setOpenLog] = useState<boolean>(false);
    const handleCloseLog = () => {
        setOpenLog(false);
    };

    const [notiType, setNotiType] = React.useState('0');
    const handleChange = (event: SelectChangeEvent) => {
        setNotiType(event.target.value as string);
    };
    const tokenFromRed = useAppSelector(getToken)
    const dispatch = useAppDispatch();




    const token = getFromStorage(STORAGE_KEYS.token)
    const fetchUser = async () => {
        try {
            const res = await getProfile({}).unwrap();
            if (res?.statusCode === 200) {
                dispatch(
                    setCredentials({
                        user: res?.data || null,
                        token: token || null,
                    }),
                );
            }
        } catch (error: any) {
            showError(error?.data?.message)
        }
    }

    useEffect(() => {
        if (window) {
            window.scrollTo({ top: 0, behavior: "auto" });
        }
    }, []);


    useEffect(() => {
        if (tokenFromRed) {
            fetchUser();
        }

    }, [])

    const DrawerList = (
        <Box className="aside_inner" role="presentation">
            <div className="aside_top">
                <figure onClick={() => navigate('/tutor/dashboard')}>
                    <img src={`/static/images/logo_favicon.svg`} alt="logo" />
                </figure>
                <button onClick={toggleDrawer(false)}><CloseIcon /></button>
            </div>
            <button className="aside_profile" onClick={() => navigate('/tutor/profile')}>
                <figure>
                    <img src={userDetails?.image ? userDetails?.image : `/static/images/user.png`} alt="logo" />
                </figure>
                <p>
                    <strong>{userDetails?.name ? userDetails?.name : "-"}</strong>
                    <span>{userDetails?.address ? userDetails?.address : "-"}</span>
                </p>
                <ArrowForwardIosIcon />
            </button>
            <div className="aside_menu">
                <List disablePadding>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate('/tutor/my-bookings')}>
                            <figure>
                                <img src={`/static/images/booking_icon.svg`} alt="Icon" />
                            </figure>
                            <p>My Bookings</p>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate('/tutor/chat')}>
                            <figure>
                                <img src={`/static/images/chat_icon.svg`} alt="Icon" />
                            </figure>
                            <p>My Chats</p>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate('/tutor/manage-users')}>
                            <figure>
                                <img src={`/static/images/pairing_icon.svg`} alt="Icon" />
                            </figure>
                            <p>Manage Users</p>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate('/tutor/manage-earnings')}>
                            <figure>
                                <img src={`/static/images/earnings_icon.svg`} alt="Icon" />
                            </figure>
                            <p>Manage Earnings</p>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate('/tutor/manage-reviews')}>
                            <figure>
                                <img src={`/static/images/reviews_icon.svg`} alt="Icon" />
                            </figure>
                            <p>Manage Reviews</p>
                        </ListItemButton>
                    </ListItem>
                </List>
                <Divider />
                <List disablePadding>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate('/tutor/contact-us')}>
                            <figure>
                                <img src={`/static/images/phone_icon.svg`} alt="Icon" />
                            </figure>
                            <p>Contact Us</p>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate('/tutor/terms-and-conditions')}>
                            <figure>
                                <img src={`/static/images/cms_icon.svg`} alt="Icon" />
                            </figure>
                            <p>Terms & Condition</p>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate('/tutor/faq')}>
                            <figure>
                                <img src={`/static/images/faq_icon.svg`} alt="Icon" />
                            </figure>
                            <p>FAQ</p>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate('/tutor/about-us')}>
                            <figure>
                                <img src={`/static/images/about_icon.svg`} alt="Icon" />
                            </figure>
                            <p>About</p>
                        </ListItemButton>
                    </ListItem>
                </List>
                <Divider className="mt_auto" />
                <List disablePadding>
                    <ListItem disablePadding>
                        <ListItemButton
                            // onClick={() => navigate('/auth/as-tutor/login')}
                            onClick={() => setOpenLog(true)}
                        >
                            <figure>
                                <img src={`/static/images/signout_icon.svg`} alt="Icon" />
                            </figure>
                            <p>Sign Out</p>
                        </ListItemButton>
                    </ListItem>
                </List>
            </div>
        </Box >
    );


    const fetchProfile = () => {
        const token = getFromStorage(STORAGE_KEYS.token);
        const userData = getFromStorage(STORAGE_KEYS.user);

        if (token) {
            dispatch(
                setCredentials({
                    user: userData || null,
                    token: token || null,
                })
            );
            dispatch(role({ roleName: "tutor" }));
        }
    };


    const fetchNotification = async () => {
        try {
            const res = await getNotifications({}).unwrap();
            if (res?.statusCode === 200) {
                setNotifcations(res?.data)
            }
        } catch (error: any) {
            showError(error?.data?.message)
        }
    }


    useEffect(() => {
        fetchProfile();
    }, [])

    useEffect(() => {
        fetchNotification()
    }, [])

    return (
        <>
            <header className="site_header v2">
                <div className="main_header">
                    <div className="conta_iner v2">
                        <nav>
                            <IconButton className="icon_btn site_hamburgur" onClick={toggleDrawer(true)}>
                                <span></span>
                            </IconButton>
                            <a onClick={() => navigate("/")} className="site_logo">
                                <figure>
                                    <img src={`/static/images/logo.png`} alt="logo" />
                                </figure>
                            </a>
                            <div className='icon_flex'>
                                {/* <IconButton className="icon_btn">
                                    <figure>
                                        <img src={`/static/images/search_icon.svg`} alt="img" />
                                    </figure>
                                </IconButton> */}
                                <IconButton
                                    className="icon_btn"
                                    id="notification-button"
                                    aria-controls={open1 ? 'notification-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open1 ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    <figure>
                                        <img src={`/static/images/bell_icon.svg`} alt="img" />
                                    </figure>
                                </IconButton>
                                <Menu
                                    className="noti_box"
                                    id="notification-menu"
                                    anchorEl={anchorEl}
                                    open={open1}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    MenuListProps={{
                                        'aria-labelledby': 'notification-button',
                                    }}
                                >
                                    <div className="head">
                                        <div className="lt_s">
                                            <h2>Notifications</h2>
                                            {/* <div className="control_group">
                                                <Select
                                                    labelId="notiType-label"
                                                    id="notiType"
                                                    value={notiType}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value="0">All</MenuItem>
                                                    <MenuItem value="10">Today</MenuItem>
                                                    <MenuItem value="20">Weekly</MenuItem>
                                                    <MenuItem value="30">Monthly</MenuItem>
                                                </Select>
                                            </div> */}
                                        </div>
                                        {/* <p>Mark all as read <CheckCircleOutlineIcon /></p> */}
                                    </div>
                                    <ul className="body">
                                        {notifications?.newNotification?.length ? (
                                            <h6>Recent notifications</h6>
                                        ) : ("")}

                                        {notifications?.newNotification?.length ? (
                                            notifications?.newNotification?.map((item: any) => {
                                                return (
                                                    <>
                                                        <li onClick={() => navigate("/tutor/my-bookings")} style={{ backgroundColor: "lightgray", padding: "8px", borderRadius: "15px" }}>

                                                            <p><strong>{item?.title}</strong> {item?.message}</p>
                                                        </li>
                                                    </>
                                                )
                                            })
                                        ) : ("")}

                                        {notifications?.oldNotification?.length ? (
                                            <h6>Older notifications</h6>
                                        ) : ("")}
                                        {notifications?.oldNotification?.length ? (
                                            notifications?.oldNotification?.map((item: any) => {
                                                return (
                                                    <>
                                                        <li onClick={() => navigate("/tutor/my-bookings")} style={{ padding: "8px", borderRadius: "15px" }}>
                                                            <p><strong>{item?.title}</strong> {item?.message}</p>
                                                        </li>
                                                    </>
                                                )
                                            })
                                        ) : ("")}

                                        {/* <li>
                                            <figure className="online">
                                                <img src={`/static/images/parent_profile_img.png`} alt="logo" />
                                            </figure>
                                            <p><strong>Catherine Bell</strong>  has started the job.</p>
                                            <Button><img src={`/static/images/map_icon.svg`} alt="Icon" /> Track Location</Button>
                                            <p><small>Today at 9:40AM</small></p>
                                        </li> */}

                                    </ul>
                                </Menu>
                            </div>
                        </nav>
                    </div>
                </div>
            </header >
            <LogoutModal
                open={openLog}
                setOpen={setOpenLog}
                onClose={handleCloseLog}
            />

            <Drawer className="header_aside" open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
        </>
    )
}

export default TutorHeader;
